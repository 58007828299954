<template>
<div style="position: relative">
<!--  <video id="player-container-id" src="https://1305614073.vod2.myqcloud.com/e97eeae8vodtranscq1305614073/d0f771408602268010956427522/v.f100220.m3u8" style="width: 100%;height: 100%;border-radius: 6px" preload="auto" playsinline webkit-playsinline></video>-->
  <div id="play-video-container"></div>
</div>
</template>

<script>


import TcAdapter from 'tcadapter';
export default {
  name: "playVideo",
  props: ['file_id'],
  data() {
    return {
      fileID: '',
      appID: process.env.VUE_APP_appID,
      sign: ''
    }
  },
  created() {
    if(this.file_id) {
      this.fileID = this.file_id
      //this.getSignature()
      setTimeout(()=>{
        this.playVideo()
      },1000)
    }
  },
  watch: {
    file_id() {
      this.fileID = this.file_id
      var video_dome= document.getElementById('play-video-container');
      video_dome.innerHTML= '';
      setTimeout(()=>{
        this.playVideo()
      },1000)
      if(this.sign != '') {

        //this.videoFun()
      }else {
        //this.getSignature()
      }
    }
  },
  methods: {
    // 获取凭证
    /*getSignature() {
      let url= this.$api.playSignature + '?file_id=' + this.fileID
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          this.sign= res.data.data.sign
          this.videoFun()
        }
      })
    },*/
    // 视频方法
    /*videoFun() {
      let _this= this;
     /!* const adapter = new TcAdapter('player-container-id', {
        fileID: _this.fileID,
        appID: _this.appID,
        psign: _this.sign,
        hlsConfig: {}
      }, function (){
        console.log(adapter.getVideoBasicInfo())
      });*!/
      var player = TCPlayer('player-container-id', { // player-container-id 为播放器容器 ID，必须与 html 中一致
        fileID: _this.fileID, // 请传入需要播放的视频 fileID（必须）
        appID: _this.appID, // 请传入点播账号的 appID（必须）
        psign: _this.sign
      });
    }*/

    getParams(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
      var r = window.location.search.substr(1).match(reg);
      if (r != null) {
        return decodeURIComponent(r[2]);
      }
      return null;
    },
    getPathParams() {
      var path = location.pathname.split('/');
      if (path[1] == 'vod-player') {
        return {
          'path': location.origin + path.slice(0, 4).join('/'),
          'appid': path[2],
          'fileid': path[3]
        }
      } else {
        return null;
      }
    },
    playVideo() {
      let _this= this;
      var rtmp = _this.getParams('rtmp'),
          flv = _this.getParams('flv'),
          m3u8 = _this.getParams('m3u8'),
          mp4 = _this.getParams('mp4'),
          live = (_this.getParams('live') == 'true' ? true : false),
          coverpic = _this.getParams('coverpic'),
          width = _this.getParams('width'),
          height = 'auto',
          volume = _this.getParams('volume'),
          flash = (_this.getParams('flash') == 'true' ? true : false),
          x5_player = (_this.getParams('x5_player') == 'false' ? false : true),
          h5_flv = (_this.getParams('h5_flv') == 'true' ? true : false),
          autoplay = (_this.getParams('autoplay') == 'true' ? true : false),
          flashUrl = (function () {
            var params = _this.getPathParams();
            if (params) {
              return params.path + '/player/release/QCPlayer.swf';
            }
            return '//imgcache.qq.com/open/qcloud/video/player/release/QCPlayer.swf'
          })(),
          log = _this.getParams('log');
      /**
       * 视频类型播放优先级
       * mobile ：m3u8>mp4
       * mobile ：m3u8>mp4
       * PC ：RTMP>flv>m3u8>mp4
       */

      var options = {
        //webrtc:"",
        m3u8: _this.file_id,
        //flv : '',
        //mp4: '',
        autoplay: true,
        muted: true,
        live: live,
        width: width ,
        height: height ,
        volume: volume || 0.5,
        flash: flash,
        flashUrl: flashUrl,
        x5_player: x5_player,
        h5_flv: true,
        h5_mp4: true,
        wording: {
          2032: '请求视频失败，请检查网络',
          2048: '请求m3u8文件失败，可能是网络错误或者跨域问题'
        },
        listener: function (msg) {
          /*if(msg.type == 'load') {
            var _div = document.createElement('div');
            _div.className = 'marker';
            _div.innerText = '测试';
            document.querySelector('.vcp-player').appendChild(_div)
          }*/
        }
      };
      window.tcplayer = new TcPlayer('play-video-container', options);
    }
  }
}
</script>

<style>
.vcp-player{
  background: unset;
}
</style>
